'use strict'

import m from 'mithril'

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

import { modal } from 'tingle.js'
import 'tingle.js/dist/tingle.css'

require('codemirror/lib/codemirror.css')
require('codemirror/theme/nord.css')
require("codemirror/mode/cdts/cdts")

const $codemirror = require("codemirror");
const $notyf = new Notyf({duration: 16000, dismissible: true})
const $modal = new modal({})
const $state = {}

function showmodal(content) {
    $modal.close()
    $modal.setContent(content)
    $modal.open()
}

window.$state = $state

const API_URL = window.location.hostname === 'localhost'? 'http://localhost:9000': '/api'

class LoginView {
    constructor() {
        this.uid = ''
    }

    oninit() {
        _setmainclass('login')
        if (localStorage.getItem('credentials')) {
            $state.user = JSON.parse(localStorage.getItem('credentials'))
            _goto('/projects')
        }
    }

    view() {
        const self = this

        if (window.location.search.startsWith('?otp=')) {
            const parts = window.location.search.split('=')[1]
            const [email, token] = parts.split('/')

            m.request({
                url: API_URL + "/user/otp/" + email + '/' + token,
                method: "GET",
                }).then(function(data) {
                    console.debug(data)
                    $state.user = {email: email, token: data.token, sessionid: email + '/' + data.token}
                    localStorage.setItem('credentials', JSON.stringify($state.user))
                    window.location.search = ''
                    _goto('/projects')
                })
                .catch(function() {
                   alert('error')
                })
            return
        }

        if ($state.user) {
            let target = '/projects'
            const hash = window.location.hash
            const i = hash.indexOf('?returnto=')
            if (i >= 0) {
                target = hash.substring(i+10)
            }
            m.route.set(target)
            return
        }
        return [
            m('h1', {style: 'margin-bottom: 8vh;'}, 'Welcome'),
            m('p', 'The Codomat will help you build your app\'s backend easily. Sign in to start building your dream.'),
            m('input', {
                id: 'login-uid', placeholder: 'email address',
                onchange: function(e) {self.uid = e.target.value}
            }),
            m('button', {onclick: function() {self.dologin()} }, 'Sign In'),
            m('p', {class: 'tip'}, 'We will never share your email or personal data with 3rd party. Also we will never spam you.'),
        ]
    }

    dologin() {
        const self = this
        //this.uid = 'test@mail.com'; this.password = 'test'
        m.request({
            url: API_URL + "/user/otp/" + self.uid,
            method: "POST",
            }).then(function(data) {
                showmodal('We just emailed you a link, please click it to complete the sign in.')
            })
            .catch(function() {
            })
    }
}


function _goto(path, params) {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    m.route.set(path, params)
}


class FilesView {
    constructor() {
        if($state.user) this.getfiles()
    }

    oninit() {
        _setmainclass('projects')
    }

    view() {
        const self = this
        if (!$state.user) {
            _goto('/login')
            return
        }
        const files = Object.entries(self.files || {})
        return [
            m('h1', 'Projects'),
            m('div', {class: 'toolbar'}, [
                m('button', {
                    onclick: function(){self.newfile()},
                }, 'New Project'),
            ]),
            m('table', {class: 'files'}, files.map(
                (idobj) => m('tr', {onclick: () => _goto('/editor/' + idobj[0]), class: 'file-entry'}, [
                    m('td', {class: 'id'}, '#' + idobj[0]),
                    m('td', {class: 'name'}, idobj[1].name)
                ])
            )),
            files.length > 0? null: m('span', {class: 'tip'}, 'no projects yet, create a new one now'), // TODO better guidance
            m('span', {
                style: 'float: right;',
            }, 'logged in as ' + $state.user.email),
        ]
    }

    getfiles() {
        const self = this
        m.request({
            method: 'GET',
            url: API_URL + '/files',
            headers: {
                Authentication: $state.user.sessionid
            }
        }).then(function(data) {
            self.files = data.records
            console.debug('files=', self.files)
        })
    }

    newfile() {
        const self = this
        const name = prompt('Your project name:')
        if (!name) {
            return
        }

        m.request({
            method: 'POST',
            url: API_URL + '/files',
            body: {name: name, content: '', tags: '', notes: ''},
            headers: {
                Authentication: $state.user.sessionid
            }
        }).then(function(data) {
            self.getfiles()
            m.route.set('/editor/' + data.id)
        })
    }
}


class EditorView {
    constructor() {
        const self = this
        self.fileid = m.route.param('fileid')
        self.editor = undefined
        self.inited = undefined
        if ($state.user) this.getfile(self.fileid)
    }

    oninit() {
        _setmainclass('editor')
    }

    view() {
        const self = this
        const fileid = m.route.param('fileid')
        if (!$state.user) {
            m.route.set('/login?returnto=/editor/'+fileid)
            return
        }

        if (self.inited !== fileid) {
            return []
        }

        if (!self.editor) {
            try {
                const elem = document.getElementById('code-editor')
                self.editor = $codemirror.fromTextArea(elem, {
                    mode: 'cdts',
                    theme: 'nord',
                    lineNumbers: true,
                    onchange: function() {self.changed = true; m.redraw()}, // TODO
                    cursorBlinkRate: 0,
                })
                window.$editor = self.editor
            } catch(e) {
                //console.error(e)
                setTimeout(() => m.redraw(), 500)
            }
        }

        let result = null
        if (self.result) {
            result = m('div', {id: 'build-result'},
                [
                    m('h3', 'Your API is ready'),
                    m('p', 'Congrats, the Codomat generated the API implementation for you! Now what?'),
                    m('p',
                        [
                        'You can start developing your app\'s frontend. You can interact with the API using the interface described in the ',
                        m('a', {'target':'_blank','href': self.result.apidoc_url},
                            ' API Documentation'
                        ),
                        '. '
                        ]
                    ),
                    m('p',
                        [
                        'You can download (a slow) implementation of the API and deploy it on your own (self hosting):',
                            m('button', {class: 'no-cta', onclick: function() {self.crmrequest('free-download')}},
                                'Download'
                            )
                        ]
                    ),
                    m('p',
                        [
                        `For serious projects, we can optimize your API for best
                        performance first then deliver you the source and support
                        you all the way to deploying your API.`,
                            m('button', {class: 'cta', onclick: function() {self.crmrequest('premium-support')}},
                                'Optimize my API'
                            )
                        ]
                    ),
                ]
            )
        }

        return [
            m('h1', self.file.name),
            //m('div', 'editing file #' + fileid + ''),
            m('div', {class: 'toolbar'}, [
                m('button', {
                    class: 'icon icon-save',
                    onclick: function(){self.savefile()},
                }, 'save' +  (self.changed? '*': '')),
                m('button', {
                    class: 'icon icon-build',
                    onclick: function(){self.build()},
                }, 'build'),
                self.result? m('button', {
                    class: 'icon icon-download',
                    onclick: function(){
                        const elem = document.querySelector('#build-result')
                        elem.scrollIntoView({behavior: 'smooth'})
                    },
                }, 'Download'): null,
            ]),
            m('textarea', {style: 'display: none;', id: 'code-editor', value: self.file.content}),
            result
        ]
    }

    getfile(fileid) {
        const self = this
        m.request({
            method: 'GET',
            url: API_URL + '/files/' + fileid,
            headers: {
                Authentication: $state.user.sessionid
            }
        }).then(function(data) {
            self.file = data.records[fileid]
            self.inited = fileid
        })
    }

    savefile(quiet) {
        const self = this
        const payload = Object.assign({}, self.file)
        payload.content = self.editor.getValue()
        if (!quiet) _showworking(true)
        return m.request({
            method: 'POST',
            url: API_URL + '/files/' + self.fileid,
            body: payload,
            headers: {
                Authentication: $state.user.sessionid
            }
        }).then(function(data) {
            if (!quiet) {
                $notyf.success('Project saved')
                _showworking(false)
            }
        }).catch(function(e) {
            $notyf.error('Cannot save now, please try again later')
            if (!quiet) _showworking(false)
        })
    }

    crmrequest(op) {
        const self = this
        // TODO display response info
        m.request({
            url: API_URL + `/crm/${self.file.name}/${op}`,
            method: "POST",
            headers: {
                Authentication: $state.user.sessionid
            }
            }).then(function(data) {
                let msg = ['All good, we opened a support ticket for you.']
                msg.push(`You can access the ticket using the link\n<a href="${data.crm_task} target="_blank">${data.crm_task}</a>.`)
                if (data.crm_user.password) {
	            msg.push(`We created a CRM account for you:\n    username: ${data.crm_user.username}\n    password: ${data.crm_user.password}`)
                }
                msg.push(`--\nIf you have troubles logging in to the CRM, you can always go to <a href="https://crm.codoma.tech">crm.codoma.tech</a> and use "Reset Password" function with the username ${data.crm_user.username}.`)
                showmodal(msg.join('\n\n').replace(/\n/g, '<br>'))
            })
            .catch(function() {
            })
    }

    async build() {
        const self = this

        _showworking(true)

        await self.savefile(true)

        m.request({
            method: 'POST',
            url: API_URL + '/build/' + self.fileid,
            body: null,
            headers: {
                Authentication: $state.user.sessionid
            }
        }).then(function(data) {
            const n = data.build_info.nendpoints
            $notyf.success(`Built successfully: ${n} endpoints`)
            _showworking(false)
            self.result = data
        }).catch(function(e) {
            const response = e.response
            let msg = e.response.msg || 'Code #501'
            if (response.line)
                msg = 'Line #' + response.line + ': ' + msg
            $notyf.error(msg)
            _showworking(false)
        })
    }

    onbeforeremove() {
        //const save = confirm('you will lose your changes, are you sure?') // TODO
        const cm = document.querySelector('.CodeMirror')
        if (cm) cm.remove()
    }
}


function start() {
    const root = document.querySelector('#main')
    m.route(root, '/', {
        //'/': HomeView,
        '/': LoginView,
        '/projects': FilesView,
        '/editor/:fileid': EditorView,
    })
}

function _showworking(show) {
    document.getElementById('curtain').style.display = show? 'block': 'none'
    document.querySelector('#logo-img').src = show? 'https://codomat-assets.netlify.app/img/logo-working.gif': 'https://codomat-assets.netlify.app/img/logo.png'
}

function _setmainclass(cls) {
    document.querySelector('#main').className = cls
}

document.addEventListener("DOMContentLoaded", function() {

    const img = new Image()
    img.src = 'https://codomat-assets.netlify.app/img/logo-working.gif' // preload
    document.querySelector('#logo-img').src = 'https://codomat-assets.netlify.app/img/logo.png'

    const curtain = document.createElement('div')
    curtain.id = 'curtain'
    curtain.style.display = 'none'
    document.body.append(curtain)

    document.getElementById('logo').onclick = function() {
        m.route.set('/')
        return false
    }

    start()
})
